import { AuthState, AppAction, auth } from '@safefleet/react-common'
import { REAUTH_STATE_CHANGED, REFRESH_TOKEN } from './actions'

interface AuthStateExtended extends AuthState {
    reauth: boolean,
}
export const initialState: AuthStateExtended = {
    ...auth.initialState,
    reauth: false,
}

export function reducer(state = initialState, action: AppAction): AuthStateExtended {
    switch (action.type) {
        case REAUTH_STATE_CHANGED: {
            const { reauth } = action.payload!
            return {
                ...state,
                reauth,
            }
        }
        case REFRESH_TOKEN: {
            const { accessToken, refreshToken } = action.payload!
            return {
                ...state,
                tokens: {
                    ...state.tokens,
                    accessToken,
                    refreshToken,
                },
            }
        }
        default:{
            return auth.reducer(state, action)
        }
    }
}
